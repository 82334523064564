import PropTypes from 'prop-types'
// @mui
import { Stack } from '@mui/material'
import { StyledRoot, StyledContent } from './styles'

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
}

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot>
      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  )
}
