import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Drivers and post new Drivers as data to the API

const initialState = {
  isLoading: true,
  success: null,
  error: null
}

const slice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
      state.success = null
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
      state.success = null
    },

    // GET DRIVERS

    getDriversSuccess(state, action) {
      state.isLoading = null
      state.drivers = action.payload
      state.success = true
    },

    // POST DRIVERS
    createOrUpdateDriversSuccess(state, action) {
      state.isLoading = null
      state.drivers = action.payload
      state.success = true
    },

    resetState(state) {
      state.isLoading = false
      state.success = null
      state.error = null
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getDrivers = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.get('/api/v1/drivers')
    dispatch(actions.getDriversSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateDrivers = (driver) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/drivers/create-or-update', driver)
    dispatch(actions.createOrUpdateDriversSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const resetDriversState = () => async (dispatch) => {
  dispatch(actions.resetState())
}
