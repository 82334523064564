// routes
import { PATH_DASHBOARD } from '../../../routes/paths'

import SvgColor from '../../../components/svg-color'

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: 'Loads',
    items: [
      // USER
      {
        title: 'dispatch',
        path: PATH_DASHBOARD.dispatch.root,
        icon: ICONS.analytics,
        children: [
          { title: 'dispatch board', path: PATH_DASHBOARD.dispatch.board },
          { title: 'load manager', path: PATH_DASHBOARD.dispatch.loadManager },
          { title: 'carrier finder', path: PATH_DASHBOARD.dispatch.carrierFinder },
        ],
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'admin',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.user,
        children: [
          { title: 'customers', path: PATH_DASHBOARD.admin.customers },
          { title: 'shippers', path: PATH_DASHBOARD.admin.shippers },
          { title: 'consignees', path: PATH_DASHBOARD.admin.consignees },
          { title: 'drivers', path: PATH_DASHBOARD.admin.drivers },
          { title: 'trucks', path: PATH_DASHBOARD.admin.trucks },
          {
            title: 'offerred trucks',
            path: PATH_DASHBOARD.admin.trucks + '/offered',
          },
          { title: 'trailers', path: PATH_DASHBOARD.admin.trailers },
          {
            title: 'external carriers',
            path: PATH_DASHBOARD.admin.externalCarriers,
          },
          { title: 'custom brokers', path: PATH_DASHBOARD.admin.customBrokers },
          {
            title: 'factoring companies',
            path: PATH_DASHBOARD.admin.factoringCompanies,
          },
          // { title: 'users', path: PATH_DASHBOARD.user.list },
          { title: 'other numbers', path: PATH_DASHBOARD.admin.otherNumers },
          { title: 'third party', path: PATH_DASHBOARD.admin.thirdParty },
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },

          // { title: 'create', path: PATH_DASHBOARD.user.new },
          // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
          { title: 'users', path: PATH_DASHBOARD.admin.users },
        ],
      },
    ],
  },
  {
    subheader: '',
    items: [{ title: 'IFTA', path: '#disabled', icon: ICONS.menuItem, disabled: true }],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Sales Manager',
        path: '#disabled',
        icon: ICONS.menuItem,
        disabled: true,
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Accounting',
        path: '#disabled',
        icon: ICONS.menuItem,
        disabled: true,
      },
    ],
  },
  {
    subheader: 'reporting',
    items: [
      {
        title: 'reports',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.invoice,
        children: [
          {
            title: 'manage reports',
            path: PATH_DASHBOARD.reports.manageReports,
          },
          {
            title: 'advanced statistics',
            path: PATH_DASHBOARD.reports.advancedStatistics,
          },
          { title: 'tax reports', path: PATH_DASHBOARD.reports.taxReports },
        ],
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Integrations',
        path: '#disabled',
        icon: ICONS.menuItem,
        disabled: true,
      },
    ],
  },
  {
    subheader: 'support',
    items: [
      {
        title: 'Requests',
        path: PATH_DASHBOARD.support.root,
        icon: ICONS.invoice,
        children: [
          {
            title: 'contact requests',
            path: PATH_DASHBOARD.support.contactRequests,
          },
          {
            title: 'quote requests',
            path: PATH_DASHBOARD.support.quoteRequests,
          },
        ],
      },
      {
        title: 'help',
        path: PATH_DASHBOARD.help.root,
        icon: ICONS.invoice,
        children: [
          {
            title: 'training videos',
            path: PATH_DASHBOARD.help.trainingVideos,
          },
          {
            title: 'getting started guide',
            path: PATH_DASHBOARD.help.gettingStarted,
          },
          {
            title: 'unlock all loads',
            path: PATH_DASHBOARD.help.unlockAllLoads,
          },
          {
            title: 'contact information',
            path: PATH_DASHBOARD.help.contactInformation,
          },
          { title: 'my tickets/bugs', path: PATH_DASHBOARD.help.myTickets },
          {
            title: 'suggestions & auditions',
            path: PATH_DASHBOARD.help.suggestionsAuditions,
          },
          { title: 'IT surveys', path: PATH_DASHBOARD.help.itSurveys },
          {
            title: 'open test account',
            path: PATH_DASHBOARD.help.openTestAccount,
          },
        ],
      },
    ],
  },
]

export default navConfig
