import PropTypes from 'prop-types'
import { useRef, useState, useEffect } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material'
// hooks
import useOffSetTop from '../../hooks/useOffSetTop'
import useResponsive from '../../hooks/useResponsive'
// utils
import { bgBlur } from '../../utils/cssStyles'
// config
import { HEADER } from '../../config-global'
// routes
import { PATH_PAGE } from '../../routes/paths'
// components
//
import navConfig from './nav/config-navigation'
import NavMobile from './nav/mobile'
import NavDesktop from './nav/desktop'

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null)

  const theme = useTheme()

  const isDesktop = useResponsive('up', 'md')

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP)

  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const shouldHideHeader = scrollPosition > 0

  return (
    <>
      {!shouldHideHeader && (
        <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
          <Toolbar
            disableGutters
            sx={{
              height: {
                xs: HEADER.H_MOBILE,
                md: HEADER.H_MAIN_DESKTOP,
              },
              transition: theme.transitions.create(['height', 'background-color'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }),
              ...(isOffset && {
                ...bgBlur({ color: theme.palette.background.default }),
                height: {
                  md: HEADER.H_MAIN_DESKTOP - 16,
                },
              }),
            }}
          >
            <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
              <RouterLink to="/">
                <img alt="logo" src="/logo/cubistics_logo_full.png" style={{ height: 60, width: 'auto' }} />
              </RouterLink>

              <Box sx={{ flexGrow: 1 }} />
              {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_PAGE.getAQuote}
                sx={{
                  borderRadius: '999px',
                  padding: '12px 32px',
                  // Reduce the padding on smaller screens
                  '@media (max-width: 600px)': {
                    padding: '9px 18px',
                  },
                }}
              >
                Get A Quote
              </Button>
              {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
            </Container>
          </Toolbar>

          {isOffset && <Shadow />}
        </AppBar>
      )}
    </>
  )
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
}

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  )
}
