import { Suspense, lazy } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')))
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')))
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')))
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')))

// DASHBOARD: GENERAL
export const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')))

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')))
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')))
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')))
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')))
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')))
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')))

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/dashboard/PermissionDeniedPage')))

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))

export const HomePage = Loadable(lazy(() => import('../pages/HomePage')))

export const CarriersPage = Loadable(lazy(() => import('../pages/CarriersPage')))
export const GetAQuotePage = Loadable(lazy(() => import('../pages/GetAQuotePage')))
export const ServicesPage = Loadable(lazy(() => import('../pages/ServicesPage')))
export const ShippersPage = Loadable(lazy(() => import('../pages/ShippersPage')))
export const InternationalPage = Loadable(lazy(() => import('../pages/InternationalPage')))

export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')))
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')))
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')))
export const TermsConditionPage = Loadable(lazy(() => import('../pages/TermsConditions')))
export const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicy')))
// export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')))
// export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')))
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')))
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')))

// DISPATCH
export const DispatchBoard = Loadable(lazy(() => import('../pages/dashboard/DispatchBoard')))
export const DispatchLoadCreateEdit = Loadable(lazy(() => import('../pages/dashboard/DispatchLoadCreateEdit')))
export const DispatchCarrierFinder = Loadable(lazy(() => import('../pages/dashboard/DispatchCarrierFinder')))
export const DispatchLoadManager = Loadable(lazy(() => import('../pages/dashboard/DispatchLoadManager')))
export const DispatchTruckSearch = Loadable(lazy(() => import('../pages/dashboard/DispatchTruckSearch')))

// ADMIN
export const AdminConsignees = Loadable(lazy(() => import('../pages/dashboard/AdminConsignees')))
export const AdminCustomBrokers = Loadable(lazy(() => import('../pages/dashboard/AdminCustomBrokers')))
export const AdminCustomers = Loadable(lazy(() => import('../pages/dashboard/AdminCustomers')))
export const AdminDrivers = Loadable(lazy(() => import('../pages/dashboard/AdminDrivers')))
export const AdminExternalCarriers = Loadable(lazy(() => import('../pages/dashboard/AdminExternalCarriers')))
export const AdminFactoringCompanies = Loadable(lazy(() => import('../pages/dashboard/AdminFactoringCompanies')))
export const AdminLoadManager = Loadable(lazy(() => import('../pages/dashboard/AdminLoadManager')))
export const AdminOtherNumbers = Loadable(lazy(() => import('../pages/dashboard/AdminOtherNumbers')))
export const AdminShippers = Loadable(lazy(() => import('../pages/dashboard/AdminShippers')))
export const AdminThirdParty = Loadable(lazy(() => import('../pages/dashboard/AdminThirdParty')))
export const AdminTrailers = Loadable(lazy(() => import('../pages/dashboard/AdminTrailers')))
export const AdminTrucks = Loadable(lazy(() => import('../pages/dashboard/AdminTrucks')))
export const AdminOfferedTrucks = Loadable(lazy(() => import('../pages/dashboard/AdminOfferedTrucks')))
export const AdminUsers = Loadable(lazy(() => import('../pages/dashboard/UserListPage')))

// HELP
export const HelpContactInformation = Loadable(lazy(() => import('../pages/dashboard/HelpContactInformation')))
export const HelpGettingStartedGuide = Loadable(lazy(() => import('../pages/dashboard/HelpGettingStartedGuide')))
export const HelpITSurveys = Loadable(lazy(() => import('../pages/dashboard/HelpITSurveys')))
export const HelpMyTickets = Loadable(lazy(() => import('../pages/dashboard/HelpMyTickets')))
export const HelpOpenTestAccount = Loadable(lazy(() => import('../pages/dashboard/HelpOpenTestAccount')))
export const HelpSuggestionsAuditions = Loadable(lazy(() => import('../pages/dashboard/HelpSuggestionsAuditions')))
export const HelpTrainingVideos = Loadable(lazy(() => import('../pages/dashboard/HelpTrainingVideos')))
export const HelpUnlockAllLoads = Loadable(lazy(() => import('../pages/dashboard/HelpUnlockAllLoads')))

// RERPORTS
export const ReportsAdvancedStatistics = Loadable(lazy(() => import('../pages/dashboard/ReportsAdvancedStatistics')))
export const ReportsManageReports = Loadable(lazy(() => import('../pages/dashboard/ReportsManageReports')))
export const ReportsTaxReports = Loadable(lazy(() => import('../pages/dashboard/ReportsTaxReports')))

// SUPPORT
export const SupportContactRequests = Loadable(lazy(() => import('../pages/dashboard/SupportContactRequests')))

export const SupportQuoteRequests = Loadable(lazy(() => import('../pages/dashboard/SupportQuoteRequests')))
