import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to consume saferwatch API

const SAFERWATCH_URL =
  'https://www.saferwatch.com/webservices/CarrierService32.php?Action=CarrierLookup&ServiceKey=DemoServiceKey&CustomerKey=DemoCustomerKey&number='

const initialState = {
  isLoading: false,
  error: null,
  data: []
}

const slice = createSlice({
  name: 'saferwatch',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET SAFERWATCH DATA
    getSaferWatchDataSuccess(state, action) {
      state.isLoading = null
      state.data = action.payload
    }
  }
})
export default slice.reducer
export const { actions } = slice

export const getSaferWatchData = (number) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading())
    const response = await axios.get(SAFERWATCH_URL + number)
    console.log(SAFERWATCH_URL + number)
    dispatch(slice.actions.getSaferWatchDataSuccess(response.data))
  } catch (error) {
    dispatch(slice.actions.hasError(error.message))
  }
}
