import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch custom brokers and post new custom brokers as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'customBrokers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET CUSTOM BROKERS

    getCustomBrokersSuccess(state, action) {
      state.isLoading = null
      state.customBrokers = action.payload.map((customBroker) => ({
        id: customBroker.id,
        name: customBroker.name,
        crossing: customBroker.crossing,
        telephone: customBroker.telephone,
        ext: customBroker.ext,
        tollfree: customBroker.tollfree,
        fax: customBroker.fax,
        isActive: customBroker.is_active,
        files: customBroker.files
      }))
    },

    // POST CUSTOM BROKERS

    createOrUpdateCustomBrokersSuccess(state, action) {
      // Update the state with the new custom broker
      console.log(action.payload)
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getCustomBrokers = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.get('/api/v1/brokers/')
    dispatch(actions.getCustomBrokersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateCustomBrokers = (customBroker) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/brokers/create-or-update', customBroker)
    dispatch(actions.createOrUpdateCustomBrokersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}
