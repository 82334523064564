import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to manage API calls from get a quote page

const initialState = {
  isLoading: false,
  success: null,
  error: null,
  quoteRequests: []
}

const slice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
      state.success = null
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
      state.success = null
    },

    // POST Message
    isSuccess(state, action) {
      state.isLoading = null
      state.success = true
    },

    getQuoteRequestsSuccess(state, action) {
      state.isLoading = null
      state.success = true
      state.quoteRequests = action.payload
    },

    resetState(state) {
      state.isLoading = false
      state.success = null
      state.error = null
    }
  }
})

export default slice.reducer
export const { actions } = slice

export const quoteRequest = (data) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/public/quote-request', data)
    dispatch(actions.isSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const getQuoteRequests = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())

    const response = await axios.get('/api/v1/public/quote-request')

    dispatch(actions.getQuoteRequestsSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const resetQuoteState = () => async (dispatch) => {
  dispatch(actions.resetState())
}
