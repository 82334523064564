import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link } from '@mui/material'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img alt="logo" src="/logo/cubistics_logo.png" style={{ height: 60, width: 'auto' }} />
    </Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
}

export default Logo
