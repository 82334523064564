import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
// slices

import officesReducer from './slices/offices'
import customersReducer from './slices/customers'
import salesRepsReducer from './slices/salesReps'
import userReducer from './slices/user'
import consigneesReducer from './slices/consignees'
import shippersReucer from './slices/shippers'
import companiesReducer from './slices/companies'
import customBrokersReducer from './slices/customBrokers'
import carriersReducer from './slices/carriers'
import driversReducer from './slices/drivers'
import otherNumbersReducer from './slices/otherNumbers'
import saferwatch from './slices/saferwatch'
import thirdPartyReducer from './slices/thirdParty'
import trailersReducer from './slices/trailers'
import trucksReducer from './slices/trucks'
import loadsReducer from './slices/loads'
import contactReducer from './slices/contact'
import quoteReducer from './slices/quote'
import dispatchersReducer from './slices/dispatchers'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
}

const rootReducer = combineReducers({
  offices: officesReducer,
  customers: customersReducer,
  salesReps: salesRepsReducer,
  user: userReducer,
  consignees: consigneesReducer,
  shippers: shippersReucer,
  companies: companiesReducer,
  customBrokers: customBrokersReducer,
  carriers: carriersReducer,
  drivers: driversReducer,
  otherNumbers: otherNumbersReducer,
  saferwatch: saferwatch,
  thirdParty: thirdPartyReducer,
  trailers: trailersReducer,
  trucks: trucksReducer,
  loads: loadsReducer,
  contact: contactReducer,
  quote: quoteReducer,
  dispatchers: dispatchersReducer,
})

export { rootPersistConfig, rootReducer }
