import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Trucks and post new Trucks as data to the API

const initialState = {
  isLoading: true,
  isOfferedGetLoading: true,
  isOfferedPostLoading: false,
  isOfferedPutLoading: false,
  error: null,
  errorOfferedGet: null,
  errorOfferedPost: null,
  errorOfferedPut: null
}

const slice = createSlice({
  name: 'trucks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    startOfferedGetLoading(state) {
      state.errorOfferedGet = null
      state.isOfferedGetLoading = true
    },

    startOfferedPostLoading(state) {
      state.errorOfferedPost = null
      state.isOfferedPostLoading = true
    },

    startOfferedPutLoading(state) {
      state.errorOfferedPut = null
      state.isOfferedPutLoading = true
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    hasOfferedGetError(state, action) {
      state.isOfferedGetLoading = null
      state.errorOfferedGet = action.payload
    },

    hasOfferedPostError(state, action) {
      state.isOfferedPostLoading = null
      state.errorOfferedPost = action.payload
    },

    hasOfferedPutError(state, action) {
      state.isOfferedPutLoading = null
      state.errorOfferedPut = action.payload
    },

    // GET TRUCKS

    getTrucksSuccess(state, action) {
      state.isLoading = null
      state.trucks = action.payload.map((truck) => ({
        id: truck.id,
        number: truck.number,
        type: truck.type,
        licensePlate: truck.license_plate,
        plateExpiry: truck.plate_expiry,
        inpectionExpiry: truck.inspection_expiry,
        status: truck.status,
        ownership: truck.ownership,
        mileage: truck.mileage,
        axles: truck.axles,
        year: truck.year,
        fuelType: truck.fuel_type,
        startDate: truck.start_date,
        deativationDate: truck.deactivation_date,
        forIFTA: truck.for_ifta,
        registeredState: truck.registered_state,
        insurancePolicyNumber: truck.insurance_policy_number,
        weight: truck.weight,
        files: truck.files
      }))
    },

    getOfferedTrucksSuccess(state, action) {
      state.isOfferedGetLoading = null
      state.offeredTrucks = action.payload
    },

    // POST TRUCKS
    createOrUpdateTrucksSuccess(state, action) {
      console.log('Truck Posted Successfully', action.payload)
      // Check status code
    },

    createOfferedTrucksSuccess(state, action) {
      console.log('Offered Truck Created Successfully', action.payload)
      // Check status code
    },

    updateOfferedTrucksSuccess(state, action) {
      console.log('Offered Truck Updated Successfully', action.payload)
      // Check status code
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getTrucks = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())

    const response = await axios.get('/api/v1/trucks')

    dispatch(actions.getTrucksSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateTrucks = (data) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())

    const response = await axios.post('/api/v1/trucks/create-or-update', data)

    dispatch(actions.createOrUpdateTrucksSuccess(response.data))
    // Check status code
    if (response.status === 201 || response.status === 200) {
      dispatch(actions.getTrucks())
    }
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const getOfferedTrucks = () => async (dispatch) => {
  try {
    dispatch(actions.startOfferedGetLoading())

    const response = await axios.get('/api/v1/trucks/offered-trucks/')

    dispatch(actions.getOfferedTrucksSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasOfferedGetError(error))
  }
}

export const createOfferedTrucks = (data) => async (dispatch) => {
  try {
    dispatch(actions.startOfferedPostLoading())

    const response = await axios.post('/api/v1/trucks/offered-trucks/', data)

    dispatch(actions.createOfferedTrucksSuccess(response.data))
    // Check status code
    if (response.status === 201 || response.status === 200) {
      dispatch(actions.getOfferedTrucks())
    }
  } catch (error) {
    dispatch(actions.hasOfferedPostError(error))
  }
}

export const updateOfferedTrucks = (data) => async (dispatch) => {
  try {
    dispatch(actions.startOfferedPutLoading())

    const response = await axios.put(`/api/v1/trucks/offered-trucks/`, data)

    dispatch(actions.updateOfferedTrucksSuccess(response.data))
    // Check status code
    if (response.status === 201 || response.status === 200) {
      dispatch(actions.getOfferedTrucks())
    }
  } catch (error) {
    dispatch(actions.hasOfferedPutError(error))
  }
}
