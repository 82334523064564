import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Customers and post new Customers as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'trailers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET TRAILERS

    getTrailersSuccess(state, action) {
      state.isLoading = null
      state.trailers = action.payload.map((trailer) => ({
        id: trailer.id,
        number: trailer.number,
        type: trailer.type,
        licensePlate: trailer.license_plate,
        plateExpiry: trailer.plate_expiry,
        inpectionExpiry: trailer.inspection_expiry,
        internalNotes: trailer.internal_notes,
        status: trailer.status,
        ownership: trailer.ownership,
        model: trailer.model,
        year: trailer.year,
        axles: trailer.axles,
        registeredState: trailer.registered_state,
        vehicleIdNumber: trailer.vehicle_id_number,
        deactivationDate: trailer.deactivation_date,
        dotExpiryDate: trailer.dot_expiry_date,
        files: trailer.files
      }))
    },

    // POST TRAILERS
    createOrUpdateTrailersSuccess(state, action) {
      console.log('Trailer Posted Successfully', action.payload)
      // Check status code
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getTrailers = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.get('/api/v1/trailers')
    dispatch(actions.getTrailersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateTrailers = (data) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/trailers', data)
    dispatch(actions.createOrUpdateTrailersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}
