import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice Manage Active and Pending Loads

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  loadCreateSuccess: false,
  isLoadingByID: false,
  isLoadingCreateOrUpdate: false,
  error: null,
  errorByID: null,
  errorCreateOrUpdate: null,
  loadStatus: 1, // frontend/src/_mock/_loadStatusOptions.js
  currentLoadToEdit: null,
  currentLoadError: null,
  currentShipperOnEdit: null,
  id: null,
  load: null,
  loads: [],

  allShippersInForm: [],
  shipperAddError: null,
  allConsigneesInForm: [
    {
      // This is the default value
      consignee: '',
      location: '',
      date: new Date(),
      time: '',
      description: '',
      tlltl: '',
      qyt: 0,
      weight: 0,
      value: 0,
      delivery_notes: '',
      po_numbers: '',
    },
  ],
  linkedDrivers: [],

  loadUpdateLoading: false,
  loadUpdateError: null,
  loadUpdateSuccess: null,

  // Load Shipper
  loadShipperCreateLoading: false,
  loadShipperCreateSuccess: null,
  loadShipperCreateError: null,

  loadShipperUpdateLoading: false,
  loadShipperUpdateSuccess: null,
  loadShipperUpdateError: null,

  loadShipperDeleteLoading: false,
  loadShipperDeleteSuccess: null,
  loadShipperDeleteError: null,

  loadShippers: [],

  // Load Consignee
  loadConsigneeCreateLoading: false,
  loadConsigneeCreateError: null,
  loadConsigneeCreateSuccess: null,

  // Cosignee Update
  loadConsigneeUpdateLoading: false,
  loadConsigneeUpdateError: null,
  loadConsigneeUpdateSuccess: null,

  // Cosignee Delete
  loadConsigneeDeleteLoading: false,
  loadConsigneeDeleteError: null,
  loadConsigneeDeleteSuccess: null,

  loadConsignees: [],

  // Load Other Charges State
  loadOtherCharges: [],
  otherChargesUpdateLoading: false,
  otherChargesUpdateError: null,
  otherChargesUpdateSuccess: null,

  // Load Other Advances State
  loadOtherAdvances: [],
  otherAdvancesUpdateLoading: false,
  otherAdvancesUpdateError: null,
  otherAdvancesUpdateSuccess: null,

  // Load Carrier
  loadCarrier: null,

  // Carrier Create Update
  carrierCreateUpdateLoading: false,
  carrierCreateUpdateError: null,
  carrierCreateUpdateSuccess: false,

  // carrier Fee Charges
  carrierFeeCharges: [],
  carrierFeeChargesUpdateLoading: false,
  carrierFeeChargesUpdateError: null,
  carrierFeeChargesUpdateSuccess: null,

  // carrier Fee Agreed Rates
  carrierFeeAgreedRates: [],
  carrierFeeAgreedRatesUpdateLoading: false,
  carrierFeeAgreedRatesUpdateError: null,
  carrierFeeAgreedRatesUpdateSuccess: null,

  // carrier Fee Advances
  carrierFeeAdvances: [],
  carrierFeeAdvancesUpdateLoading: false,
  carrierFeeAdvancesUpdateError: null,
  carrierFeeAdvancesUpdateSuccess: null,

  // Laod Drivers
  loadDrivers: [],
  // Driver Create
  loadDriverCreateLoading: false,
  loadDriverCreateError: null,
  loadDriverCreateSuccess: null,

  // Driver Delete
  loadDriverDeleteLoading: false,
  loadDriverDeleteSuccess: false,
  loadDriverDeleteError: null,

  // Files
  loadFileCreateLoading: false,
  loadFileCreateError: null,
  loadFileCreateSuccess: null,

  loadFiles: [],
  loadFilesLoading: false,
  loadFilesError: null,
  loadFilesSuccess: null,

  loadFileDeleteLoading: false,
  loadFileDeleteError: null,
  loadFileDeleteSuccess: null,

  // Load Notes
  loadNotesCreateUpdateLoading: false,
  loadNotesCreateUpdateError: null,
  loadNotesCreateUpdateSuccess: null,
  loadNotes: [],

  // Logs
  loadLogsRequestLoading: false,
  loadLogsRequestSuccess: null,
  loadLogsRequestFailed: null,
  loadLogs: [],
}

const slice = createSlice({
  name: 'loads',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
      state.loadCreateSuccess = false
      state.loadUpdateSuccess = null
    },

    loadingByID(state) {
      state.errorByID = null
      state.isLoadingByID = true
    },

    startCreateOrUpdateLoading(state) {
      state.errorCreateOrUpdate = null
      state.isLoadingCreateOrUpdate = true
    },

    onLoadCreateStart(state) {
      state.isCreateLoading = true
    },

    onLoadCreateSuccess(state, action) {
      state.isCreateLoading = false
      state.loadCreateSuccess = true
      state.load = action.payload
    },

    onLoadCreateError(state, action) {
      state.isCreateLoading = false
      state.currentLoadError = action.payload
      state.loadCreateSuccess = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    hasErrorByID(state, action) {
      state.isLoadingByID = null
      state.errorByID = action.payload
    },

    hasCreateOrUpdateError(state, action) {
      state.isLoadingCreateOrUpdate = null
      state.errorCreateOrUpdate = action.payload
    },

    // GET LOADS
    getLoadsSuccess(state, action) {
      state.isLoading = null
      state.loads = action.payload
      state.error = null
    },

    // GET LOAD BY ID
    getLoadByIDSuccess(state, action) {
      state.isLoadingByID = null
      state.load = action.payload
      state.loadShippers = action.payload.shippers
      state.loadConsignees = action.payload.consignees
      state.error = null
    },

    // create or update load
    createOrUpdateLoadSuccess(state, action) {
      state.isLoading = null
      state.load = action.payload
      state.error = null
    },
    isPendingLoadStatusChangeSuccess(state, action) {
      state.isLoading = null
      state.loadStatus = action.payload
      state.error = null
    },
    isPendingLoadStatusError(state, action) {
      state.isLoading = null
      state.loadStatus = 1
      state.error = action.payload
    },
    onShipperAddSuccess(state, action) {
      state.isLoading = null
      state.currentShipperOnEdit = action.payload
      let newShipper = action.payload
      // If the shipper is already in the list, don't add it again but update it
      let shipperIndex = state.allShippersInForm.findIndex((shipper) => shipper.shipper === newShipper.shipper)
      if (shipperIndex === -1) {
        // Copy current shipper to all shippers
        let shippersData = [...state.allShippersInForm]
        shippersData.push(newShipper)
        state.allShippersInForm = shippersData
      } else {
        state.allShippersInForm[shipperIndex] = newShipper
      }
      state.error = null
    },
    onShipperAddRemoveError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },
    onConsigneeAddSuccess(state, action) {
      state.isLoading = null
      let newConsignee = action.payload
      // If the consignee is already in the list, don't add it again but update it
      let consigneeIndex = state.allConsigneesInForm.findIndex((consignee) => consignee.id === newConsignee.id)
      if (consigneeIndex === -1) {
        state.allConsigneesInForm.push(newConsignee)
      } else {
        state.allConsigneesInForm[consigneeIndex] = newConsignee
      }
      state.error = null
    },
    onConsigneeAddRemoveError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },
    onShipperRemoveSuccess(state, action) {
      state.isLoading = null
      let shipper = action.payload
      let index = state.allShippersInForm.findIndex((shipperInList) => shipperInList.shipper === shipper.shipper)

      state.allShippersInForm.splice(index, 1)
      // Find if any shipper left, if yes then set the last item as current shipper
      if (state.allShippersInForm.length > 0) {
        state.currentShipperOnEdit = state.allShippersInForm[state.allShippersInForm.length - 1]
      }
      state.error = null
    },

    onUpdateCurrentShipperOnEdit(state, action) {
      state.currentShipperOnEdit = action.payload
    },

    onConsigneeRemoveSuccess(state, action) {
      state.isLoading = null
      let index = action.payload
      state.allConsigneesInForm.splice(index, 1)
      state.error = null
    },

    loadUpdateStart(state, action) {
      state.loadUpdateLoading = true
      state.loadUpdateError = null
      state.loadUpdateSuccess = null
    },

    loadUpdateSuccess(state, action) {
      state.loadUpdateLoading = false
      state.loadUpdateError = null
      state.loadUpdateSuccess = true
      state.load = action.payload
    },

    loadUpdateError(state, action) {
      state.loadUpdateLoading = false
      state.loadUpdateError = action.payload
      state.loadUpdateSuccess = false
    },

    loadShipperCreateLoading(state, action) {
      state.loadShipperCreateLoading = false
      state.loadShipperCreateError = null
      state.loadShipperCreateSuccess = null
    },

    loadShipperCreateSuccess(state, action) {
      state.loadShipperCreateLoading = false
      state.loadShipperCreateSuccess = true
      let shipper = action.payload
      state.loadShippers = [...state.loadShippers, shipper]
    },

    loadShipperCreateError(state, action) {
      state.loadShipperCreateLoading = false
      state.loadShipperCreateError = action.payload
      state.loadShipperCreateSuccess = false
    },

    loadShipperUpdateLoading(state, action) {
      state.loadShipperUpdateLoading = false
      state.loadShipperUpdateError = null
      state.loadShipperUpdateSuccess = null
    },

    loadShipperUpdateSuccess(state, action) {
      state.loadShipperUpdateLoading = false
      state.loadShipperUpdateSuccess = true
      let shipper = action.payload
      let index = state.loadShippers.findIndex((shipperInList) => shipperInList.id === shipper.id)
      state.loadShippers[index] = shipper
    },

    loadShipperUpdateError(state, action) {
      state.loadShipperUpdateLoading = false
      state.loadShipperUpdateError = action.payload
      state.loadShipperUpdateSuccess = false
    },

    loadShipperDeleteLoading(state, action) {
      state.loadShipperDeleteLoading = true
      state.loadShipperDeleteError = null
    },

    loadShipperDeleteSuccess(state, action) {
      state.loadShipperDeleteLoading = false
      state.loadShipperDeleteError = null
      state.loadShipperDeleteSuccess = true
      let shipper = action.payload
      let id = shipper.id
      let index = state.loadShippers.findIndex((shipperInList) => shipperInList.id === id)
      state.loadShippers.splice(index, 1)
    },

    loadShipperDeleteError(state, action) {
      state.loadShipperDeleteLoading = false
      state.loadShipperDeleteError = action.payload
      state.loadShipperDeleteSuccess = false
    },

    appendEmptyShipperInLoadShippers(state, action) {
      state.loadShippers = [...state.loadShippers, action.payload]
    },

    // Load Consignee
    loadConsigneeCreateLoading(state, action) {
      state.loadConsigneeCreateLoading = true
      state.loadConsigneeCreateError = null
      state.loadConsigneeCreateSuccess = null
    },

    loadConsigneeUpdateLoading(state, action) {
      state.loadConsigneeUpdateLoading = true
      state.loadConsigneeUpdateError = null
      state.loadConsigneeUpdateSuccess = null
    },

    loadConsigneeDeleteLoading(state, action) {
      state.loadConsigneeDeleteLoading = true
      state.loadConsigneeDeleteError = null
      state.loadConsigneeDeleteSuccess = null
    },

    loadConsigneeCreateSuccess(state, action) {
      state.loadConsigneeCreateLoading = false
      state.loadConsigneeCreateError = null
      state.loadConsigneeCreateSuccess = true
      let consignee = action.payload
      state.loadConsignees = [...state.loadConsignees, consignee]
    },

    loadConsigneeUpdateSuccess(state, action) {
      state.loadConsigneeUpdateLoading = false
      state.loadConsigneeUpdateError = null
      state.loadConsigneeUpdateSuccess = true
      let consignee = action.payload
      let index = state.loadConsignees.findIndex((consigneeInList) => consigneeInList.id === consignee.id)
      state.loadConsignees[index] = consignee
    },

    loadConsigneeDeleteSuccess(state, action) {
      state.loadConsigneeDeleteLoading = false
      state.loadConsigneeDeleteError = null
      state.loadConsigneeDeleteSuccess = true

      let consignee = action.payload
      let index = state.loadConsignees.findIndex((consigneeInList) => consigneeInList.id === consignee.id)
      state.loadConsignees.splice(index, 1)
    },

    loadConsigneeCreateRequestFailed(state, action) {
      state.loadConsigneeCreateLoading = false
      state.loadConsigneeCreateError = action.payload
      state.loadConsigneeCreateSuccess = false
    },

    loadConsigneeUpdateRequestFailed(state, action) {
      state.loadConsigneeUpdateLoading = false
      state.loadConsigneeUpdateError = action.payload
      state.loadConsigneeUpdateSuccess = false
    },

    loadConsigneeDeleteRequestFailed(state, action) {
      state.loadConsigneeDeleteLoading = false
      state.loadConsigneeDeleteError = action.payload
      state.loadConsigneeDeleteSuccess = false
    },

    appendEmptyConsigneeInLoadConsingees(state, action) {
      state.loadConsignees = [...state.loadConsignees, action.payload]
    },

    // Set current Load to edit
    setCurrentLoadToEdit(state, action) {
      state.id = action.payload.id
    },
    setCurrentLoadError(state, action) {
      state.currentLoadError = action.payload
    },

    setLinkedDrivers(state, action) {
      state.linkedDrivers = action.payload
    },
    setLoadID(state, action) {
      state.id = action.payload
    },
    setLoad(state, action) {
      state.load = action.payload
    },

    // Other Charges
    loadOtherChargesUpdateStart(state, action) {
      state.otherChargesUpdateLoading = true
      state.otherChargesUpdateError = null
      state.otherChargesUpdateSuccess = null
    },

    loadOtherChargesUpdateSuccess(state, action) {
      state.otherChargesUpdateLoading = false
      state.otherChargesUpdateSuccess = true
      state.loadOtherCharges = action.payload
    },

    loadOtherChargesUpdateFailed(state, action) {
      state.otherChargesUpdateLoading = false
      state.otherChargesUpdateError = action.payload
      state.otherChargesUpdateSuccess = false
    },

    // Other Advances
    loadOtherAdvancesUpdateStart(state, action) {
      state.otherAdvancesUpdateLoading = true
      state.otherAdvancesUpdateError = null
      state.otherAdvancesUpdateSuccess = null
    },

    loadOtherAdvancesUpdateSuccess(state, action) {
      state.otherAdvancesUpdateLoading = false
      state.loadOtherAdvances = action.payload
      state.otherAdvancesUpdateSuccess = true
    },

    loadOtherAdvancesUpdateError(state, action) {
      state.otherAdvancesUpdateLoading = false
      state.otherAdvancesUpdateError = action.payload
      state.otherAdvancesUpdateSuccess = false
    },

    resetOtherChargesState(state) {
      state.otherChargesUpdateLoading = false
      state.otherChargesUpdateError = null
      state.otherChargesUpdateSuccess = null
    },

    resetOtherAdvancesState(state) {
      state.otherAdvancesUpdateLoading = false
      state.otherAdvancesUpdateError = null
      state.otherAdvancesUpdateSuccess = null
    },

    // Carrier Create Update
    carrierCreateUpdateStart(state, action) {
      state.carrierCreateUpdateLoading = true
      state.carrierCreateUpdateError = null
      state.carrierCreateUpdateSuccess = null
    },

    carrierCreateUpdateSuccess(state, action) {
      state.carrierCreateUpdateLoading = false
      state.carrierCreateUpdateSuccess = true
      state.loadCarrier = action.payload
    },

    carrierCreateUpdateError(state, action) {
      state.carrierCreateUpdateLoading = false
      state.carrierCreateUpdateError = action.payload
      state.carrierCreateUpdateSuccess = false
    },

    resetCarrierCreateUpdateState(state) {
      state.carrierCreateUpdateLoading = false
      state.carrierCreateUpdateError = null
      state.carrierCreateUpdateSuccess = null
    },

    // Carrier Fee Agreed Rates
    carrierFeeAgreedRatesUpdateStart(state, action) {
      state.carrierFeeAgreedRatesUpdateLoading = true
      state.carrierFeeAgreedRatesUpdateError = null
      state.carrierFeeAgreedRatesUpdateSuccess = null
    },

    carrierFeeAgreedRatesUpdateSuccess(state, action) {
      state.carrierFeeAgreedRatesUpdateLoading = false
      state.carrierFeeAgreedRatesUpdateSuccess = true
      state.carrierFeeAgreedRates = action.payload
    },

    carrierFeeAgreedRatesUpdateError(state, action) {
      state.carrierFeeAgreedRatesUpdateLoading = false
      state.carrierFeeAgreedRatesUpdateError = action.payload
      state.carrierFeeAgreedRatesUpdateSuccess = false
    },

    resetCarrierFeeAgreedRatesState(state) {
      state.carrierFeeAgreedRatesUpdateLoading = false
      state.carrierFeeAgreedRatesUpdateError = null
      state.carrierFeeAgreedRatesUpdateSuccess = null
    },

    // Carrier Fee Advances
    carrierFeeAdvancesUpdateStart(state, action) {
      state.carrierFeeAdvancesUpdateLoading = true
      state.carrierFeeAdvancesUpdateError = null
      state.carrierFeeAdvancesUpdateSuccess = null
    },

    carrierFeeAdvancesUpdateSuccess(state, action) {
      state.carrierFeeAdvancesUpdateLoading = false
      state.carrierFeeAdvancesUpdateSuccess = true
      state.carrierFeeAdvances = action.payload
    },

    carrierFeeAdvancesUpdateError(state, action) {
      state.carrierFeeAdvancesUpdateLoading = false
      state.carrierFeeAdvancesUpdateError = action.payload
      state.carrierFeeAdvancesUpdateSuccess = false
    },

    resetCarrierFeeAdvancesState(state) {
      state.carrierFeeAdvancesUpdateLoading = false
      state.carrierFeeAdvancesUpdateError = null
      state.carrierFeeAdvancesUpdateSuccess = null
    },

    // Carrier Fee Charges
    carrierFeeChargesUpdateStart(state, action) {
      state.carrierFeeChargesUpdateLoading = true
      state.carrierFeeChargesUpdateError = null
      state.carrierFeeChargesUpdateSuccess = null
    },

    carrierFeeChargesUpdateSuccess(state, action) {
      state.carrierFeeChargesUpdateLoading = false
      state.carrierFeeChargesUpdateSuccess = true
      state.carrierFeeCharges = action.payload
    },

    carrierFeeChargesUpdateError(state, action) {
      state.carrierFeeChargesUpdateLoading = false
      state.carrierFeeChargesUpdateError = action.payload
      state.carrierFeeChargesUpdateSuccess = false
    },

    // Files Create
    loadFileCreateStart(state, action) {
      state.loadFileCreateLoading = true
      state.loadFileCreateError = null
      state.loadFileCreateSuccess = null
    },

    loadFileCreateSuccess(state, action) {
      state.loadFileCreateLoading = false
      state.loadFileCreateSuccess = true
      state.loadFiles = action.payload
    },

    loadFileCreateError(state, action) {
      state.loadFileCreateLoading = false
      state.loadFileCreateError = action.payload
      state.loadFileCreateSuccess = false
    },

    // Get Files List
    loadFilesRequestStart(state, action) {
      state.loadFilesLoading = true
      state.loadFilesError = null
      state.loadFilesSuccess = null
    },

    loadFilesRequestSuccess(state, action) {
      state.loadFilesLoading = false
      state.loadFilesSuccess = true
      state.loadFiles = action.payload
    },

    loadFilesRequestError(state, action) {
      state.loadFilesLoading = false
      state.loadFilesError = action.payload
      state.loadFilesSuccess = false
    },

    // Files Delete
    loadFileDeleteStart(state, action) {
      state.loadFileDeleteLoading = true
      state.loadFileDeleteError = null
      state.loadFileDeleteSuccess = null
    },

    loadFileDeleteSuccess(state, action) {
      state.loadFileDeleteLoading = false
      state.loadFileDeleteSuccess = true
      state.loadFiles = action.payload
    },

    loadFileDeleteError(state, action) {
      state.loadFileDeleteLoading = false
      state.loadFileDeleteError = action.payload
      state.loadFileDeleteSuccess = false
    },

    // Load Notes Create Update
    loadNotesCreateUpdateStart(state, action) {
      state.loadNotesCreateUpdateLoading = true
      state.loadNotesCreateUpdateError = null
      state.loadNotesCreateUpdateSuccess = null
    },

    loadNotesCreateUpdateSuccess(state, action) {
      state.loadNotesCreateUpdateLoading = false
      state.loadNotesCreateUpdateSuccess = true
      state.loadNotes = action.payload
    },

    loadNotesCreateUpdateError(state, action) {
      state.loadNotesCreateUpdateLoading = false
      state.loadNotesCreateUpdateError = action.payload
      state.loadNotesCreateUpdateSuccess = false
    },

    // Load Logs Request
    loadLogsRequestStart(state, action) {
      state.loadLogsRequestLoading = true
      state.loadLogsRequestSuccess = null
      state.loadLogsRequestFailed = null
    },

    loadLogsRequestSuccess(state, action) {
      state.loadLogsRequestLoading = false
      state.loadLogsRequestSuccess = true
      state.loadLogs = action.payload
    },

    loadLogsRequestFailed(state, action) {
      state.loadLogsRequestLoading = false
      state.loadLogsRequestFailed = action.payload
      state.loadLogsRequestSuccess = false
    },

    // Load Drivers
    loadDriverCreateStart(state, action) {
      state.loadDriverCreateLoading = true
      state.loadDriverCreateError = null
      state.loadDriverCreateSuccess = null
    },

    loadDriverCreateSuccess(state, action) {
      state.loadDriverCreateLoading = false
      state.loadDriverCreateSuccess = true
      state.loadDrivers = action.payload
    },

    loadDriverCreateError(state, action) {
      state.loadDriverCreateLoading = false
      state.loadDriverCreateError = action.payload
      state.loadDriverCreateSuccess = false
    },

    loadDriverDeleteStart(state, action) {
      state.loadDriverDeleteLoading = true
      state.loadDriverDeleteError = null
      state.loadDriverDeleteSuccess = null
    },

    loadDriverDeleteSuccess(state, action) {
      state.loadDriverDeleteLoading = false
      state.loadDriverDeleteSuccess = true
      state.loadDrivers = action.payload
    },

    loadDriverDeleteError(state, action) {
      state.loadDriverDeleteLoading = false
      state.loadDriverDeleteError = action.payload
      state.loadDriverDeleteSuccess = false
    },

    // Reset Load Carrier
    resetLoadCarrierCreateUpdateState(state) {
      state.loadCarrier = null
      state.carrierCreateUpdateError = false
      state.carrierCreateUpdateLoading = false
      state.carrierCreateUpdateSuccess = false
    },

    // Reset Load Carrier Fee Charges
    resetLoadCarrierFeeChargesUpdateState(state) {
      state.carrierFeeCharges = []
      state.carrierFeeChargesUpdateError = false
      state.carrierFeeChargesUpdateLoading = false
      state.carrierFeeChargesUpdateSuccess = false
    },

    // Reset Load Carrier Fee Agreed Rates
    resetLoadCarrierFeeAgreedRatesUpdateState(state) {
      state.carrierFeeAgreedRates = []
      state.carrierFeeAgreedRatesUpdateError = false
      state.carrierFeeAgreedRatesUpdateLoading = false
      state.carrierFeeAgreedRatesUpdateSuccess = false
    },

    // Reset Load Carrier Fee Advances
    resetLoadCarrierFeeAdvancesUpdateState(state) {
      state.carrierFeeAdvances = []
      state.carrierFeeAdvancesUpdateError = false
      state.carrierFeeAdvancesUpdateLoading = false
      state.carrierFeeAdvancesUpdateSuccess = false
    },

    // Reset Load Files
    resetLoadFileCreateState(state) {
      state.loadFiles = []
      state.loadFileCreateError = false
      state.loadFileCreateLoading = false
      state.loadFileCreateSuccess = false
    },

    resetLoadLogsRequestState(state) {
      state.loadLogs = []
      state.loadLogsRequestFailed = false
      state.loadLogsRequestLoading = false
      state.loadLogsRequestSuccess = false
    },

    resetShippersState(state) {
      state.loadShipperCreateLoading = false
      state.loadShipperDeleteLoading = false
      state.loadShipperUpdateLoading = false

      state.loadShipperCreateSuccess = false
      state.loadShipperUpdateSuccess = false
      state.loadShipperDeleteSuccess = false

      state.loadShipperCreateError = null
      state.loadShipperUpdateError = null
      state.loadShipperDeleteError = null

      state.loadShippers = []
    },

    resetConsigneeState(state) {
      state.loadConsigneeCreateLoading = false
      state.loadConsigneeCreateError = null
      state.loadConsigneeCreateSuccess = false
      state.loadConsigneeUpdateLoading = false
      state.loadConsigneeUpdateError = null
      state.loadConsigneeUpdateSuccess = false
      state.loadConsigneeDeleteLoading = false
      state.loadConsigneeDeleteError = null
      state.loadConsigneeDeleteSuccess = false

      state.loadConsignees = []
    },
  },
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getLoads = () => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    const response = await axios.get('/api/v1/loads/')
    dispatch(actions.getLoadsSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const getLoadByID = (id) => async (dispatch) => {
  dispatch(actions.loadingByID())
  try {
    const response = await axios.get(`/api/v1/loads/${id}`)
    dispatch(actions.getLoadByIDSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasErrorByID(error))
  }
}

export const createOrUpdateLoad = (load) => async (dispatch) => {
  dispatch(actions.startCreateOrUpdateLoading())
  try {
    const response = await axios.post('/api/v1/loads/create-or-update/', load)
    dispatch(actions.createOrUpdateLoadSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasCreateOrUpdateError(error))
  }
}

export const loadCreate = (status) => async (dispatch) => {
  dispatch(actions.onLoadCreateStart())
  try {
    const response = await axios.post('/api/v1/loads/create/', status)
    dispatch(actions.onLoadCreateSuccess(response.data))
  } catch (error) {
    dispatch(actions.onLoadCreateError(error))
  }
}

export const loadUpdate = (id, body) => async (dispatch) => {
  dispatch(actions.loadUpdateStart())
  try {
    const response = await axios.put(`/api/v1/loads/${id}/update/`, body)
    dispatch(actions.loadUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadUpdateError(error))
  }
}

export const loadStatusChange = (value) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    dispatch(actions.isPendingLoadStatusChangeSuccess(value))
  } catch (error) {
    dispatch(actions.isPendingLoadStatusError(error))
  }
}

export const onShipperAdd = (shipper) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    dispatch(actions.onShipperAddSuccess(shipper))
  } catch (error) {
    dispatch(actions.onShipperAddRemoveError(error))
  }
}

export const onShipperRemove = (index) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    dispatch(actions.onShipperRemoveSuccess(index))
  } catch (error) {
    dispatch(actions.onShipperAddRemoveError(error))
  }
}

export const onConsigneeAdd = (consignee) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    dispatch(actions.onConsigneeAddSuccess(consignee))
  } catch (error) {
    dispatch(actions.onConsigneeAddRemoveError(error))
  }
}

export const onConsigneeRemove = (index) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    dispatch(actions.onConsigneeRemoveSuccess(index))
  } catch (error) {
    dispatch(actions.onConsigneeAddRemoveError(error))
  }
}

export const setCurrentLoadToEdit = (load) => async (dispatch) => {
  try {
    dispatch(actions.setCurrentLoadToEdit(load))
  } catch (error) {
    dispatch(actions.setCurrentLoadError(error))
  }
}

export const setLinkedDriversInStore = (drivers) => async (dispatch) => {
  try {
    dispatch(actions.setLinkedDrivers(drivers))
  } catch (error) {
    dispatch(actions.setCurrentLoadError(error))
  }
}

export const onUpdateCurrentShipperOnEdit = (currentShipperToEdit) => async (dispatch) => {
  try {
    dispatch(actions.onUpdateCurrentShipperOnEdit(currentShipperToEdit))
  } catch (error) {
    dispatch(actions.setCurrentLoadError(error))
  }
}

export const setLoadID = (id) => async (dispatch) => {
  dispatch(actions.setLoadID(id))
}

export const setLoad = (load) => async (dispatch) => {
  dispatch(actions.setLoad(load))
}

// POST, PUT, PATCH, DELETE http://localhost:8000/api/v1/loads/shippers/
// {
//   "date": "2019-08-24",
//   "show_time": "2019-08-24T14:15:22Z",
//   "description": "string",
//   "type": "string",
//   "qty": -2147483648,
//   "weight": -2147483648,
//   "value": -2147483648,
//   "shipping_notes": "string",
//   "po_numbers": "string",
//   "load": 0,
//   "shipper": 0,
//   "custom_broker": 0
// }

// POST, PUT, PATCH, DELETE http://localhost:8000/api/v1/loads/consignees/
// {
//   "date": "2019-08-24",
//   "show_time": "2019-08-24T14:15:22Z",
//   "description": "string",
//   "type": "string",
//   "qty": -2147483648,
//   "weight": -2147483648,
//   "value": -2147483648,
//   "delivery_notes": "string",
//   "po_numbers": "string",
//   "load": 0,
//   "consignee": 0
// }

// Handling One to Many Objects CRUD API Calls here.

// Load Shipper
export const loadShipperCreate = (shipper) => async (dispatch) => {
  dispatch(actions.loadShipperCreateLoading())
  try {
    const response = await axios.post('/api/v1/loads/shippers/', shipper)
    dispatch(actions.loadShipperCreateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadShipperRequestFailed(error))
  }
}

export const loadShipperUpdate = (shipper) => async (dispatch) => {
  dispatch(actions.loadShipperUpdateLoading())
  try {
    const response = await axios.put(`/api/v1/loads/shippers/${shipper.id}/update/`, shipper)
    dispatch(actions.loadShipperUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadShipperUpdateError(error))
  }
}

export const loadShipperDelete = (shipper) => async (dispatch) => {
  dispatch(actions.loadShipperDeleteLoading())
  try {
    await axios.delete(`/api/v1/loads/shippers/${shipper.id}/delete/`)
    dispatch(actions.loadShipperDeleteSuccess(shipper))
  } catch (error) {
    dispatch(actions.loadShipperDeleteError(error))
  }
}

// Load Consignee
export const loadConsigneeCreate = (consignee) => async (dispatch) => {
  dispatch(actions.loadConsigneeCreateLoading())
  try {
    const response = await axios.post('/api/v1/loads/consignees/', consignee)
    dispatch(actions.loadConsigneeCreateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadConsigneeCreateRequestFailed(error))
  }
}

export const loadConsigneeUpdate = (consignee) => async (dispatch) => {
  dispatch(actions.loadConsigneeUpdateLoading())
  try {
    const response = await axios.put(`/api/v1/loads/consignees/${consignee.id}/update/`, consignee)
    dispatch(actions.loadConsigneeUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadConsigneeUpdateRequestFailed(error))
  }
}

export const loadConsigneeDelete = (consignee) => async (dispatch) => {
  dispatch(actions.loadConsigneeDeleteLoading())
  try {
    await axios.delete(`/api/v1/loads/consignees/${consignee.id}/delete/`)
    dispatch(actions.loadConsigneeDeleteSuccess(consignee))
  } catch (error) {
    dispatch(actions.loadConsigneeDeleteRequestFailed(error))
  }
}

export const loadOtherChargesBulkCreateUpdate = (otherCharges) => async (dispatch) => {
  dispatch(actions.loadOtherChargesUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/other-charges/bulk-create/', otherCharges)
    dispatch(actions.loadOtherChargesUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadOtherChargesUpdateFailed(error))
  }
}

export const loadOtherAdvancesBulkCreateUpdate = (otherAdvances) => async (dispatch) => {
  dispatch(actions.loadOtherAdvancesUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/other-advances/bulk-create/', otherAdvances)
    dispatch(actions.loadOtherAdvancesUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadOtherAdvancesUpdateError(error))
  }
}

export const carrierCreateUpdate = (carrier) => async (dispatch) => {
  dispatch(actions.carrierCreateUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/load-carrier/create-update/', carrier)
    dispatch(actions.carrierCreateUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.carrierCreateUpdateError(error))
  }
}

export const carrierFeeAgreedRatesBulkCreateUpdate = (carrierFeeAgreedRates) => async (dispatch) => {
  dispatch(actions.carrierFeeAgreedRatesUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/load-carrier/carrier-fee-agreed-rate/', carrierFeeAgreedRates)
    dispatch(actions.carrierFeeAgreedRatesUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.carrierFeeAgreedRatesUpdateError(error))
  }
}

export const carrierFeeAdvancesBulkCreateUpdate = (carrierFeeAdvances) => async (dispatch) => {
  dispatch(actions.carrierFeeAdvancesUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/load-carrier/carrier-fee-advance-bulk-create/', carrierFeeAdvances)
    dispatch(actions.carrierFeeAdvancesUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.carrierFeeAdvancesUpdateError(error))
  }
}

export const carrierFeeChargesBulkCreateUpdate = (carrierFeeCharges) => async (dispatch) => {
  dispatch(actions.carrierFeeChargesUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/load-carrier/carrier-fee-charge-bulk-create/', carrierFeeCharges)
    dispatch(actions.carrierFeeChargesUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.carrierFeeChargesUpdateError(error))
  }
}

export const loadFileCreate = (file, load_id) => async (dispatch) => {
  dispatch(actions.loadFileCreateStart())

  try {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('load_id', load_id)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    const response = await axios.post(`/api/v1/loads/files/`, file, config)
    dispatch(actions.loadFileCreateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadFileCreateError(error))
  }
}

export const loadFileDelete = (file) => async (dispatch) => {
  dispatch(actions.loadFileDeleteStart())
  try {
    const response = await axios.delete(`/api/v1/loads/files/?file_id=${file.id}`)
    dispatch(actions.loadFileDeleteSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadFileDeleteError(error))
  }
}

export const loadFilesRequest = (load_id) => async (dispatch) => {
  dispatch(actions.loadFilesRequestStart())
  try {
    const response = await axios.get(`/api/v1/loads/files/?load_id=${load_id}`)
    dispatch(actions.loadFilesRequestSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadFilesRequestError(error))
  }
}

export const loadNotesCreateUpdate = (note) => async (dispatch) => {
  dispatch(actions.loadNotesCreateUpdateStart())
  try {
    const response = await axios.post('/api/v1/loads/notes/', note)
    dispatch(actions.loadNotesCreateUpdateSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadNotesCreateUpdateError(error))
  }
}

export const loadLogsRequest = (load_id) => async (dispatch) => {
  dispatch(actions.loadLogsRequestStart())
  try {
    const response = await axios.get(`/api/v1/loads/logs/?load_id=${load_id}`)
    dispatch(actions.loadLogsRequestSuccess(response.data))
  } catch (error) {
    dispatch(actions.loadLogsRequestFailed(error))
  }
}

export const resetShippersState = () => async (dispatch) => {
  dispatch(actions.resetShippersState())
}

export const resetConsigneeState = () => async (dispatch) => {
  dispatch(actions.resetConsigneeState())
}

export const appendEmptyShipperInLoadShippers = (shipper) => async (dispatch) => {
  dispatch(actions.appendEmptyShipperInLoadShippers(shipper))
}

export const appendEmptyConsigneeInLoadConsingees = (consignee) => async (dispatch) => {
  dispatch(actions.appendEmptyConsigneeInLoadConsingees(consignee))
}
