import { createSlice } from '@reduxjs/toolkit'
// utils
import axios from '../../utils/axios'
//
// import { dispatch } from '../store'

// ----------------------------------------------------------------------

// Slice to fetch the office list as data from the API and store it in the Redux store

const initialState = {
  isLoading: false,
  error: null,
  currentOffice: null
}

const slice = createSlice({
  name: 'offices',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET OFFICES
    getOfficesSuccess(state, action) {
      state.isLoading = false
      state.offices = action.payload
    },

    officeChange(state, action) {
      state.currentOffice = action.payload
    }
  }
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export function getOffices() {
  return async (dispatch) => {
    dispatch(actions.startLoading())
    try {
      const response = await axios.get('/api/v1/management/office/')
      dispatch(actions.getOfficesSuccess(response.data))
    } catch (error) {
      dispatch(actions.hasError(error))
    }
  }
}

export function changeOffice(officeId) {
  return async (dispatch) => {
    dispatch(actions.officeChange(officeId))
  }
}
