// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  termsAndconditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  careers: '/careers',
  carriers: '/carriers',
  shippers: '/shippers',
  getAQuote: '/get-a-quote',
  services: '/services',
  international: '/international',
  // faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),

  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    consignees: path(ROOTS_DASHBOARD, '/admin/consignees'),
    customBrokers: path(ROOTS_DASHBOARD, '/admin/custom-brokers'),
    customers: path(ROOTS_DASHBOARD, '/admin/customers'),
    drivers: path(ROOTS_DASHBOARD, '/admin/drivers'),
    externalCarriers: path(ROOTS_DASHBOARD, '/admin/external-carriers'),
    factoringCompanies: path(ROOTS_DASHBOARD, '/admin/factoring-companies'),
    loadManagers: path(ROOTS_DASHBOARD, '/admin/load-managers'),
    otherNumers: path(ROOTS_DASHBOARD, '/admin/other-numbers'),
    shippers: path(ROOTS_DASHBOARD, '/admin/shippers'),
    thirdParty: path(ROOTS_DASHBOARD, '/admin/third-party'),
    trailers: path(ROOTS_DASHBOARD, '/admin/trailers'),
    trucks: path(ROOTS_DASHBOARD, '/admin/trucks'),
    users: path(ROOTS_DASHBOARD, '/admin/users'),
  },
  dispatch: {
    root: path(ROOTS_DASHBOARD, '/dispatch'),
    board: path(ROOTS_DASHBOARD, '/dispatch/board'),
    detail: path(ROOTS_DASHBOARD, '/dispatch/detail'),
    create: path(ROOTS_DASHBOARD, '/dispatch/load-create'),
    createActiveLoad: path(ROOTS_DASHBOARD, '/dispatch/active/'),
    createPendingLoad: path(ROOTS_DASHBOARD, '/dispatch/pending'),
    loadManager: path(ROOTS_DASHBOARD, '/dispatch/load-manager'),
    carrierFinder: path(ROOTS_DASHBOARD, '/dispatch/carrier-finder'),
    offeredTrucks: path(ROOTS_DASHBOARD, '/dispatch/offered-trucks'),
    truckSearch: path(ROOTS_DASHBOARD, '/dispatch/truck-search'),
  },
  help: {
    root: path(ROOTS_DASHBOARD, '/help'),
    contactInformation: path(ROOTS_DASHBOARD, '/help/contact-information'),
    gettingStarted: path(ROOTS_DASHBOARD, '/help/getting-started'),
    itSurveys: path(ROOTS_DASHBOARD, '/help/it-surveys'),
    myTickets: path(ROOTS_DASHBOARD, '/help/my-tickets'),
    openTestAccount: path(ROOTS_DASHBOARD, '/help/open-test-account'),
    suggestionsAuditions: path(ROOTS_DASHBOARD, '/help/suggestions-auditions'),
    trainingVideos: path(ROOTS_DASHBOARD, '/help/training-videos'),
    unlockAllLoads: path(ROOTS_DASHBOARD, '/help/unlock-all-loads'),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    advancedStatistics: path(ROOTS_DASHBOARD, '/reports/advanced-statistics'),
    manageReports: path(ROOTS_DASHBOARD, '/reports/manage-reports'),
    taxReports: path(ROOTS_DASHBOARD, '/reports/tax-reports'),
  },

  support: {
    root: path(ROOTS_DASHBOARD, '/support'),
    contactRequests: path(ROOTS_DASHBOARD, '/support/contact-requests'),
    quoteRequests: path(ROOTS_DASHBOARD, '/support/quote-requests'),
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  // invoice: {
  //   root: path(ROOTS_DASHBOARD, '/invoice'),
  //   list: path(ROOTS_DASHBOARD, '/invoice/list'),
  //   new: path(ROOTS_DASHBOARD, '/invoice/new'),
  //   view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
  //   edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
  //   demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5')
  // }
}

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
}

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/'

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/'

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/'

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1'
