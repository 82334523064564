import PropTypes from 'prop-types'

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }) {
  return null
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
}
