import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch otherNumbers and post new otherNumbers as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'otherNumbers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET OTHER NUMBERS

    getOtherNumbersSuccess(state, action) {
      state.isLoading = null
      state.otherNumbers = action.payload.map((otherNumber) => ({
        id: otherNumber.id,
        name: otherNumber.name,
        showOnDispatch: otherNumber.show_on_dispatch,
        isActive: otherNumber.is_active
      }))
    },

    // POST OTHER NUMBERS
    createOrUpdateOtherNumbersSuccess(state, action) {
      // Update the state with the new other number
      console.log(action.payload)
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getOtherNumbers = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())

    const response = await axios.get('/api/v1/others/')

    dispatch(actions.getOtherNumbersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateOtherNumbers = (otherNumber) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/others/create-or-update', otherNumber)
    dispatch(actions.createOrUpdateOtherNumbersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}
