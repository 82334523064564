// routes
import { PATH_PAGE } from '../../../routes/paths'

import Iconify from '../../../components/iconify'

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Services',
    icon: <Iconify icon="carbon:ibm-cloud-internet-services" />,
    path: PATH_PAGE.services
  },
  {
    title: 'About Us',
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_PAGE.about
  },

  {
    title: 'Shippers',
    icon: <Iconify icon="ri:ship-2-line" />,
    path: PATH_PAGE.shippers
  },
  {
    title: 'Carriers',
    icon: <Iconify icon="octicon:container-16" />,
    path: PATH_PAGE.carriers
  },
  {
    title: 'International',
    icon: <Iconify icon="carbon:flight-international" />,
    path: PATH_PAGE.international
  }
]

export default navConfig
