import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Consignees and post new Consignees as data to the API

const initialState = {
  isLoading: true,
  success: null,
  error: null,
  consignees: []
}

const slice = createSlice({
  name: 'consignees',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
      state.success = null
    },

    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
      state.success = null
    },

    // GET CONSIGNEES
    getConsigneesSuccess(state, action) {
      state.isLoading = null
      state.consignees = action.payload
      state.success = true
    },

    // POST CONSIGNEES
    createOrUpdateConsigneesSuccess(state, action) {
      console.log(action.payload)
      state.consignees = action.payload
      state.isLoading = null
      state.success = true
    },

    resetState(state) {
      state.isLoading = false
      state.success = null
      state.error = null
    }
  }
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export const getConsignees = () => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    const response = await axios.get('/api/v1/consignees')

    dispatch(actions.getConsigneesSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateConsignee = (data) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    const response = await axios.post('/api/v1/consignees/create-or-update', data)
    dispatch(actions.createOrUpdateConsigneesSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
    console.log(error)
  }
}

export const resetConsigeesState = () => async (dispatch) => {
  dispatch(actions.resetState())
}
