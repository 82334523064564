import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Customers and post new Customers as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET CUSTOMERS

    getCustomersSuccess(state, action) {
      state.isLoading = null
      state.customers = action.payload
      state.error = null
    },

    // POST CUSTOMERS

    createCustomersSuccess(state, action) {
      state.isLoading = null
      state.message = action.payload
    },

    // GET CUSTOMER BY ID

    getCustomerById(state, action) {
      state.isLoading = false
      state.error = false
      state.customer = action.payload
    }
  }
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export function getCustomers() {
  return async (dispatch) => {
    dispatch(actions.startLoading())
    try {
      const response = await axios.get('/api/v1/customers/')
      dispatch(actions.getCustomersSuccess(response.data))
    } catch (error) {
      dispatch(actions.hasError(error))
    }
  }
}

export function createCustomer(body) {
  return async (dispatch) => {
    dispatch(actions.startLoading())
    try {
      const response = await axios.post('/api/v1/customers/create_or_update/', body)
      dispatch(actions.createCustomersSuccess(response.data))

      // Check if response is 201 or 200
      if (response.status === 201 || response.status === 200) {
        dispatch(getCustomers())
      }
    } catch (error) {
      dispatch(actions.hasError(error))
    }
  }
}

export function getCustomerDetails(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/v1/customers/${id}/`)
      dispatch(actions.getCustomerById(response.data))
    } catch (error) {}
  }
}
