import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Sales Represenatives and post new Sales Represenatives as data to the API

const initialState = {
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'salesReps',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET SALES REPS

    getSalesRepsSuccess(state, action) {
      state.isLoading = false
      state.salesReps = action.payload
    }
  }
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export function getSalesReps() {
  return async (dispatch) => {
    dispatch(actions.startLoading())
    try {
      const response = await axios.get('/api/v1/management/sales-representatives/')
      dispatch(actions.getSalesRepsSuccess(response.data))
    } catch (error) {
      dispatch(actions.hasError(error))
    }
  }
}
