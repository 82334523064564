import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch third parties and post new third parties as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'thirdParty',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // hasError

    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET THIRD PARTIES

    getThirdPartiesSuccess(state, action) {
      state.isLoading = null
      state.thirdParties = action.payload.map((thirdParty) => ({
        id: thirdParty.id,
        name: thirdParty.name,
        address: thirdParty.address,
        country: thirdParty.country,
        state: thirdParty.state,
        city: thirdParty.city,
        zip: thirdParty.zip,
        telephone: thirdParty.telephone,
        ext: thirdParty.ext,
        files: thirdParty.files
      }))
    },

    // POST THIRD PARTIES

    createOrUpdateThirdPartiesSuccess(state, action) {
      console.log('Third Party Posted Successfully', action.payload)
      // Check status code
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getThirdParties = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.get('/api/v1/thirdparty/')
    dispatch(actions.getThirdPartiesSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateThirdParties = (thirdParty) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/thirdparty/create-or-update', thirdParty)
    dispatch(actions.createOrUpdateThirdPartiesSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}
