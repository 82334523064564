import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch factoing companies and post new factoing companies  as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET FACTORING COMPANIES

    getCompaniesSuccess(state, action) {
      state.isLoading = null
      state.companies = action.payload.map((company) => ({
        id: company.id,
        name: company.name,
        address: company.address,
        city: company.city,
        zip: company.zip,
        state: company.state,
        primaryContact: company.primary_contact,
        email: company.email,
        telephone: company.telephone,
        ext: company.ext,
        tollfree: company.tollfree,
        fax: company.fax,
        secondaryContact: company.secondary_contact,
        secondaryTelephone: company.secondary_telephone,
        secondaryExt: company.secondary_ext,
        internalNotes: company.internal_notes,
        currecny: company.currency,
        paymentTerms: company.payment_terms,
        taxId: company.tax_id,
        isActive: company.is_active,
        files: company.files
      }))
    },

    // POST FACTORING COMPANIES

    createOrUpdateCompaniesSuccess(state, action) {
      console.log('Factor Company Posted Successfully', action.payload)
      // Check status code
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getCompanies = () => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.get('/api/v1/companies/')
    dispatch(actions.getCompaniesSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateCompanies = (data) => async (dispatch) => {
  try {
    dispatch(actions.startLoading())
    const response = await axios.post('/api/v1/companies/create-or-update', data)
    dispatch(actions.createOrUpdateCompaniesSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}
