import { Navigate, useRoutes } from 'react-router-dom'
// auth
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// layouts
import MainLayout from '../layouts/main'
// import SimpleLayout from '../layouts/simple'
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard'
// config
import { PATH_AFTER_LOGIN } from '../config-global'
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  Dashboard,

  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  PermissionDeniedPage,

  // Dispatch
  DispatchBoard,
  DispatchLoadCreateEdit,
  DispatchCarrierFinder,
  DispatchLoadManager,
  DispatchTruckSearch,

  // ADMIN
  AdminConsignees,
  AdminCustomBrokers,
  AdminCustomers,
  AdminDrivers,
  AdminExternalCarriers,
  AdminFactoringCompanies,
  AdminLoadManager,
  AdminOtherNumbers,
  AdminShippers,
  AdminThirdParty,
  AdminTrailers,
  AdminTrucks,
  AdminOfferedTrucks,
  AdminUsers,

  // HELP
  HelpContactInformation,
  HelpGettingStartedGuide,
  HelpITSurveys,
  HelpMyTickets,
  HelpSuggestionsAuditions,
  HelpTrainingVideos,
  HelpUnlockAllLoads,

  // REPORTS
  ReportsAdvancedStatistics,
  ReportsManageReports,
  ReportsTaxReports,

  // SUPPORT
  SupportContactRequests,
  SupportQuoteRequests,

  //
  Page500,
  Page403,
  Page404,
  HomePage,
  CarriersPage,
  GetAQuotePage,
  ServicesPage,
  InternationalPage,
  ShippersPage,
  FaqsPage,
  AboutPage,
  Contact,
  TermsConditionPage,
  PrivacyPolicyPage,

  // PricingPage,
  // PaymentPage,
  ComingSoonPage,
  MaintenancePage,
} from './elements'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'reset-password',
              element: <ResetPasswordPage />,
            },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        { path: 'app', element: <Dashboard /> },

        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'dispatch',
          children: [
            {
              element: <Navigate to="/dashboard/dispatch/board" replace />,
              index: true,
            },

            {
              path: 'board',
              element: <DispatchBoard />,
            },
            {
              path: 'detail',
              element: <DispatchLoadCreateEdit />,
            },
            {
              path: 'load-create',
              element: <DispatchLoadCreateEdit />,
            },
            {
              path: 'active',
              element: <DispatchLoadCreateEdit />,
            },
            {
              path: 'pending',
              element: <DispatchLoadCreateEdit />,
            },
            {
              path: 'load-manager',
              element: <DispatchLoadManager />,
            },
            {
              path: 'carrier-finder',
              element: <DispatchCarrierFinder />,
            },

            {
              path: 'truck-search',
              element: <DispatchTruckSearch />,
            },
          ],
        },
        {
          path: 'admin',
          children: [
            {
              element: <Navigate to="/dashboard/admin/users" replace />,
              index: true,
            },
            { path: 'consignees', element: <AdminConsignees /> },
            {
              path: 'custom-brokers',
              element: <AdminCustomBrokers />,
            },
            { path: 'customers', element: <AdminCustomers /> },
            { path: 'drivers', element: <AdminDrivers /> },
            {
              path: 'external-carriers',
              element: <AdminExternalCarriers />,
            },
            {
              path: 'factoring-companies',
              element: <AdminFactoringCompanies />,
            },
            {
              path: 'load-managers',
              element: <AdminLoadManager />,
            },
            {
              path: 'other-numbers',
              element: <AdminOtherNumbers />,
            },
            { path: 'shippers', element: <AdminShippers /> },
            { path: 'third-party', element: <AdminThirdParty /> },
            { path: 'trailers', element: <AdminTrailers /> },
            { path: 'trucks', element: <AdminTrucks /> },
            {
              path: 'trucks/offered',
              element: <AdminOfferedTrucks />,
            },
            { path: 'users', element: <AdminUsers /> },
          ],
        },
        {
          path: 'help',
          children: [
            {
              element: <Navigate to="/dashboard/help/training-videos" replace />,
              index: true,
            },
            {
              path: 'getting-started',
              element: <HelpContactInformation />,
            },
            {
              path: 'it-surveys',
              element: <HelpGettingStartedGuide />,
            },
            { path: 'my-tickets', element: <HelpITSurveys /> },
            {
              path: 'open-test-account',
              element: <HelpMyTickets />,
            },
            {
              path: 'suggestions-auditions',
              element: <HelpSuggestionsAuditions />,
            },
            {
              path: 'training-videos',
              element: <HelpTrainingVideos />,
            },
            {
              path: 'unlock-all-loads',
              element: <HelpUnlockAllLoads />,
            },
          ],
        },
        {
          path: 'support',
          children: [
            {
              element: <Navigate to="/dashboard/support/contact-requests" replace />,
              index: true,
            },
            {
              path: 'contact-requests',
              element: <SupportContactRequests />,
            },
            {
              path: 'quote-requests',
              element: <SupportQuoteRequests />,
            },
          ],
        },
        {
          path: 'reports',
          children: [
            {
              element: <Navigate to="/dashboard/reports/manage-reports" replace />,
              index: true,
            },
            {
              path: 'advanced-statistics',
              element: <ReportsAdvancedStatistics />,
            },
            {
              path: 'manage-reports',
              element: <ReportsManageReports />,
            },
            { path: 'tax-reports', element: <ReportsTaxReports /> },
          ],
        },

        { path: 'permission-denied', element: <PermissionDeniedPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'terms-and-conditions', element: <TermsConditionPage /> },
        {path: 'privacy-policy', element: <PrivacyPolicyPage />},
        { path: 'faqs', element: <FaqsPage /> },
        { path: 'carriers', element: <CarriersPage /> },
        { path: 'shippers', element: <ShippersPage /> },

        { path: 'get-a-quote', element: <GetAQuotePage /> },
        { path: 'services', element: <ServicesPage /> },
        { path: 'international', element: <InternationalPage /> },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> }
    //   ]
    // },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
