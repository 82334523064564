import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch external carriers and post new external carriers  as data to the API

const initialState = {
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET EXTERNAL CARRIERS

    getExternalCarriersSuccess(state, action) {
      state.isLoading = null
      state.externalCarriers = action.payload.map((externalCarrier) => ({
        id: externalCarrier.id,
        name: externalCarrier.carrier_name,
        address: externalCarrier.address,
        city: externalCarrier.city,
        state: externalCarrier.state,
        zip: externalCarrier.zip,
        telephone: externalCarrier.telephone,
        isSaferWatch: externalCarrier.is_safer_watch,
        files: externalCarrier.files
      }))
    },

    // POST EXTERNAL CARRIERS

    createOrUpdateExternalCarriersSuccess(state, action) {
      console.log('External Carrier Posted Successfully', action.payload)
      // Check status code
    },

    // GET EXTERNAL CARRIER BY ID

    getExternalCarrierByIdSuccess(state, action) {
      state.isLoading = false
      state.error = false
      state.externalCarrier = {
        id: action.payload.id,
        dot: action.payload.dot,
        mc: action.payload.mc,
        fc: action.payload.fc,
        carrierName: action.payload.carrier_name,
        address: action.payload.address,
        country: action.payload.country,
        state: action.payload.state,
        city: action.payload.city,
        zip: action.payload.zip,
        contactName: action.payload.contact_name,
        email: action.payload.email,
        telephone: action.payload.telephone,
        ext: action.payload.ext,
        tollfree: action.payload.tollfree,
        paymentTerms: action.payload.payment_terms,
        taxId: action.payload.tax_id,
        mcFF: action.payload.mc_ff,
        urs: action.payload.urs,
        notes: action.payload.notes,
        isBlacklisted: action.payload.is_blacklisted,
        isCorporation: action.payload.is_corporation,
        factoringCompany: action.payload.factoring_company,
        libilityCompany: action.payload.libility_company,
        libilityPolicy: action.payload.libility_policy,
        libilityExpirationDate: action.payload.liability_expiration_date,
        libilityTelephone: action.payload.libility_telephone,
        libilityExt: action.payload.libility_ext,
        libilityContact: action.payload.libility_contact,
        libilityCompanyAmount: action.payload.libility_company_amount,
        libilityNotes: action.payload.libility_notes,
        insuranceCompany: action.payload.insurance_company,
        insurancePolicy: action.payload.insurance_policy,
        insuranceExpirationDate: action.payload.insurance_expiration_date,
        insuranceTelephone: action.payload.insurance_telephone,
        insuranceExt: action.payload.insurance_ext,
        insuranceContact: action.payload.insurance_contact,
        insuranceCompanyAmount: action.payload.insurance_company_amount,
        insuranceNotes: action.payload.insurance_notes,
        cargoCompany: action.payload.cargo_company,
        cargoInsurancePolicy: action.payload.cargo_insurance_policy,
        cargoExpirationDate: action.payload.cargo_expiration_date,
        cargoTelephone: action.payload.cargo_telephone,
        cargoExt: action.payload.cargo_ext,
        cargoContact: action.payload.cargo_contact,
        cargoCompanyAmount: action.payload.cargo_company_amount,
        wsibNo: action.payload.wsib_no,
        cargoNotes: action.payload.cargo_notes,
        fmcscaInsuranceCompany: action.payload.fmcsca_insurance_company,
        fmcsaPolicy: action.payload.fmcsa_policy,
        fmcsaExpirationDate: action.payload.fmcsa_expiration_date,
        fmcsaType: action.payload.fmcsa_type,
        fmcsaCoverage: action.payload.fmcsa_coverage,
        fmcsaTelephone: action.payload.fmcsa_telephone,
        amBestRating: action.payload.am_best_rating,
        accountingPrimaryName: action.payload.accounting_primary_name,
        accountingPrimaryEmail: action.payload.accounting_primary_email,
        accountingPrimaryTelephone: action.payload.accounting_primary_telephone,
        accountingSecondaryName: action.payload.accounting_secondary_name,
        accountingSecondaryEmail: action.payload.accounting_secondary_email,
        accountingSecondaryTelephone: action.payload.accounting_secondary_telephone,
        isSaferWatch: action.payload.is_safer_watch,
        saferwatch: {
          id: action.payload.saferwatch.id,
          legalName: action.payload.saferwatch.legal_name,
          dbaName: action.payload.saferwatch.dba_name,
          address: action.payload.saferwatch.address,
          country: action.payload.saferwatch.country,
          state: action.payload.saferwatch.state,
          city: action.payload.saferwatch.city,
          zip: action.payload.saferwatch.zip,
          contactName: action.payload.saferwatch.contact_name,
          email: action.payload.saferwatch.email,
          telephone: action.payload.saferwatch.telephone,
          fax: action.payload.saferwatch.fax,
          mc_ff: action.payload.saferwatch.mc_ff,
          dot: action.payload.saferwatch.dot,
          unsafeDriving: action.payload.saferwatch.unsafe_driving,
          hocCompliance: action.payload.saferwatch.hoc_compliance,
          driverFitness: action.payload.saferwatch.driver_fitness,
          drugAndAlcohol: action.payload.saferwatch.drug_and_alcohol,
          vehicleMaintenance: action.payload.saferwatch.vehicle_maintenance,
          safety_rating: action.payload.saferwatch.safety_rating,
          isOutOfService: action.payload.saferwatch.is_out_of_service,
          isCommonOrContract: action.payload.saferwatch.is_common_or_contract,
          isBroker: action.payload.saferwatch.is_broker,
          noOfTrucks: action.payload.saferwatch.no_of_trucks,
          createdAt: action.payload.saferwatch.created_at,
          updatedAt: action.payload.saferwatch.updated_at,
          carrier: action.payload.saferwatch.carrier
        }
      }
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getExternalCarriers = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/carriers')
    dispatch(actions.getExternalCarriersSuccess(response.data))
  } catch (error) {
    console.log(error)
  }
}

export const createOrUpdate = (data) => async (dispatch) => {
  try {
    const response = await axios.post('/api/v1/carriers/create_or_udpate/', data)
    dispatch(actions.createOrUpdateSuccess(response.data))
  } catch (error) {
    console.log(error)
  }
}

export const getExternalCarrierById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/carriers/${id}/`)
    dispatch(actions.getExternalCarrierByIdSuccess(response.data))
  } catch (error) {
    console.log(error)
  }
}
