import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Shippers and post new Shippers as data to the API

const initialState = {
  isLoading: true,
  success: null,
  error: null
}

const slice = createSlice({
  name: 'shippers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null
      state.isLoading = true
      state.success = null
    },
    // hasError
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
      state.success = null
    },
    // GET SHIPPERS

    getShippersSuccess(state, action) {
      state.isLoading = null
      state.shippers = action.payload
      state.success = true
    },
    // POST SHIPPERS
    createOrUpdateShippersSuccess(state, action) {
      state.shippers = action.payload
      state.isLoading = null
      state.success = true
    },

    stateReset(state) {
      state.isLoading = false
      state.error = null
      state.success = null
    }
  }
})

export default slice.reducer
export const { actions } = slice

// Actions
export const getShippers = () => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    const response = await axios.get('/api/v1/shippers')
    dispatch(actions.getShippersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const createOrUpdateShippers = (shipper) => async (dispatch) => {
  dispatch(actions.startLoading())
  try {
    const response = await axios.post('/api/v1/shippers/create_or_update/', shipper)
    dispatch(actions.createOrUpdateShippersSuccess(response.data))
  } catch (error) {
    dispatch(actions.hasError(error))
  }
}

export const resetShipperState = () => async (dispatch) => {
  dispatch(actions.stateReset())
}
