import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'

// Slice to fetch Dispatchers and the related sales representatives.

const initialState = {
  isLoading: true,
  error: null,
  dispatchers: [],
}

const slice = createSlice({
  name: 'dispatchers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = null
      state.error = action.payload
    },

    // GET DISPATCHERS
    getDispatchersSuccess(state, action) {
      state.isLoading = null
      state.dispatchers = action.payload
      state.error = null
    },
  },
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export function getDispatchers() {
  return async (dispatch) => {
    dispatch(actions.startLoading())
    try {
      const response = await axios.get('/api/v1/accounts/dispatchers/')
      dispatch(actions.getDispatchersSuccess(response.data))
    } catch (error) {
      dispatch(actions.hasError(error))
    }
  }
}
