import { Link as RouterLink, useLocation } from 'react-router-dom'
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material'
// routes
import { PATH_PAGE } from '../../routes/paths'
import Iconify from '../../components/iconify'

// ----------------------------------------------------------------------

export const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: 'https://www.facebook.com/',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: 'https://www.instagram.com/',
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: 'https://www.linkedin.com/',
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC',
    path: 'https://www.twitter.com/',
  },
]

const LINKS = [
  {
    headline: 'Cubisitics',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.termsAndconditions },
      { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'info@cubistics.com', href: '#' }],
  },
  {
    headline: 'Addresses',
    children: [
      { name: '1 Auer Ct., East Brunswick, New Jersey, 08816', href: '#' },
      { name: '3206 Old Castle RD., Joilet, IL 60431', href: '#' },
    ],
  },
]

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation()

  const isHome = pathname === '/'

  const currentYear = new Date().getFullYear()

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        {/* <Logo sx={{ mb: 1, mx: 'auto' }} /> */}

        <Typography variant="caption" component="div">
          © All rights reserved
          <Link href="https://cubistics.com/"> @cubistics.com </Link>
        </Typography>
      </Container>
    </Box>
  )

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <RouterLink to="/">
              <img alt="logo" src="/logo/cubistics_logo.png" style={{ height: 60, width: 'auto' }} />
            </RouterLink>
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Cubistics lightens your supply chain load with customized transportation and logistics solutions that
              deliver on time and on budget every time.
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }}>
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link key={link.name} component={RouterLink} to={link.href} color="inherit" variant="body2">
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © {currentYear}. All rights reserved
        </Typography>
      </Container>
    </Box>
  )

  return isHome ? simpleFooter : mainFooter
}
